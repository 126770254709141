<template>
  <v-layout row shrink :class="layoutClasses">
    <v-flex>
      <v-text-field
        ref="input"
        class="text-field"
        :class="[status, scribeClass]"
        :placeholder="placeholder"
        @keyup.enter="submit()"
        @keyup.self.capture="TextAdded()"
        v-model="text"
        outline
      />
    </v-flex>
    <v-flex shrink>
      <v-layout column fill-height justify-center>
        <a class="text-field-submit" :class="buttonStatus" @click="submit()">
          {{ buttonText }}
        </a>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "TextField",
  data() {
    return {
      text: "",
      buttonStatus: "inactive"
    }
  },
  props: {
    status: {
      type: String,
      default: ""
    },
    show: String,
    placeholder: String,
    buttonText: String
  },
  computed: {
    ...mapGetters(["getCurrentMode"]),
    isHost() {
      return this.$store.getters.user.role == "facilitator"
    },
    currentMissions() {
      return this.$store.getters.currentMission
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    scribeClass() {
      if (this.isScribe && !this.isHost) return "scribe"
      else return ""
    },
    layoutClasses() {
      return this.status + " " + this.show
    },
    currentMode() {
      return this.getCurrentMode
    }
  },
  watch: {
    status(newValue) {
      if (newValue === "" || newValue === "correct" || newValue === "wrong") {
        this.$nextTick(() => {
          try {
            this.$refs.input.blur()
          } catch (e) {
            console.log(e.message)
          }
        })
      }
    },
    buttonStatus(newValue) {
      this.buttonStatus = newValue
    },
    text() {
      this.$emit("onChange", this.text)
    }
  },
  mounted() {
    if (this.isScribe) {
      this.$refs.input.focus()
    }
  },
  methods: {
    submit() {
      this.$emit("onSubmit", this.text)
      this.text = ""
    },
    TextAdded() {
      // console.log("+++++++++++++ text added")
      if (this.text != "" && this.text != null) {
        this.buttonStatus = "active"
        // console.log("+++++++++++++ has text")
      } else {
        this.buttonStatus = "inactive"
        // console.log("+++++++++++++ No text contained")
      }
    }
  }
}
</script>

<style lang="scss">
.text-field {
  pointer-events: none;
  input {
    margin-top: 0 !important;
    caret-color: $color-black !important;
  }
  .v-input__slot {
    margin: 0 !important;
    min-height: auto !important;
    border-radius: 10px !important;
    border: 2px solid $color-grey-light3 !important;
  }
  .v-text-field__details {
    display: none !important;
  }
}
.text-field.active {
  pointer-events: auto;
}
.text-field.correct {
  pointer-events: none;
  .v-input__slot {
    border: 2px solid $color-correct !important;
  }
}
.text-field.wrong {
  pointer-events: none;
  .v-input__slot {
    border: 2px solid $color-wrong !important;
  }
}
.text-field-submit {
  padding: 5px;
  font-weight: bold;
  color: $color-grey-light3;
  opacity: 1;
  pointer-events: none;
  cursor: not-allowed;
  transition: color 0.3s;
  user-select: none;
}
.text-field-submit:not(.correct):hover,
.text-field-submit:not(.wrong):hover {
  filter: brightness(1.2);
}
.text-field-submit.active {
  color: $primary_accent_color;
  pointer-events: auto;
  cursor: pointer;
}
.text-field-submit.correct {
  color: $color-correct;
  pointer-events: none;
  cursor: not-allowed;
}
.text-field-submit.wrong {
  color: $color-wrong;
  pointer-events: none;
  cursor: not-allowed;
}
.text-input-mission .mission-text-feild.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}
.giphy-mission .mission-text-feild.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.giphy-mission .text-field-submit.inactive {
  pointer-events: none;
  color: $color-grey-light3 !important;
}
.giphy-mission .text-field-submit.active {
  pointer-events: auto;
  color: $primary_accent_color !important;
}
.line-through input::placeholder {
  text-decoration: line-through;
}

.text-field.scribe .v-input__slot {
  animation: scribeAnimation 1.5s forwards 1s;
}
@keyframes scribeAnimation {
  0% {
    box-shadow: none;
  }
  15% {
    box-shadow: 0px 0px 7px 0px $primary_accent_color;
  }
  30% {
    box-shadow: none;
  }
  45% {
    box-shadow: 0px 0px 7px 0px $primary_accent_color;
  }
  60% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0px 0px 7px 0px $primary_accent_color;
  }
  90% {
    box-shadow: none;
  }
}
</style>
