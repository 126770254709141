var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { class: _vm.layoutClasses, attrs: { row: "", shrink: "" } },
    [
      _c(
        "v-flex",
        [
          _c("v-text-field", {
            ref: "input",
            staticClass: "text-field",
            class: [_vm.status, _vm.scribeClass],
            attrs: { placeholder: _vm.placeholder, outline: "" },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submit()
              },
              "!keyup": function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.TextAdded()
              }
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "", "justify-center": "" } },
            [
              _c(
                "a",
                {
                  staticClass: "text-field-submit",
                  class: _vm.buttonStatus,
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }